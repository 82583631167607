.relative {
  position: relative;
}
.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(255,255,255,0.7);
  @include color-mode(dark) {
    background-color: rgba(33, 37, 41, 0.7);
  }
  &.fullpage {
    position: fixed;
    background-color: rgba(255,255,255,0.3);
    .loader {
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    }
  }
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.top .loader {
    top: 1em;
    transform: translateX(-50%);
  }
  &.bottom .loader {
    top: auto;
    bottom: 1em;
    transform: translateX(-50%);
  }
  &[class^='height-'], &[class*=' height-'] {
    position: relative;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
  }
  &.height-1 {
    height: 2.5em;
  }
  &.height-2 {
    height: 5em;
  }
  &.height-3 {
    height: 7.5em;
  }
  &.height-4 {
    height: 10em;
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  vertical-align: middle;
  &.small {
    width: 40px;
    height: 40px;
    div {
      width: 24px;
      height: 24px;
    }
  }
  &.tiny {
    width: 24px;
    height: 24px;
    div {
      width: 18px;
      height: 18px;
      margin: 3px;
      border: 3px solid #fff;
      border-color: $mainColor transparent transparent transparent;
    }
  }
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $mainColor transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
}
.btn:hover {
  .loader {
    &.tiny {
      div {
        border: 3px solid #fff;
        border-color: white transparent transparent transparent;
      }
    }
    div {
      border: 8px solid #fff;
      border-color: white transparent transparent transparent;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}