.dropdown-menu {
  --bs-dropdown-bg: white;
  @include color-mode(dark) {
    --bs-dropdown-bg: #{$gray-900};
  }
}
.list-group {
  --bs-list-group-bg: white;
  @include color-mode(dark) {
    --bs-list-group-bg: #{$gray-900};
  }
}
.card {
  --bs-card-bg: white;
  @include color-mode(dark) {
    --bs-card-bg: #{$gray-900};
  }
}
.btn {
  .icon {
    width: 1.2em;
    height: 1.2em;
  }
}
.btn-light {
  @include color-mode(dark) {
    color: $gray-100;
    background-color: $gray-800;
    border-color: $gray-700;
    &:hover {
      color: white;
      background-color: $gray-700;
    }
  }
}
.btn-slug {
  color: $link-color;
  text-transform: uppercase;
  text-decoration: $link-decoration;
  background-color: $gray-100;
  border-color: $gray-200;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &.mention {
    text-transform: inherit;
  }
  @include color-mode(dark) {
    color: $link-color-dark;
    background-color: $gray-800;
    border-color: $gray-700;
    &:hover {
      color: $link-hover-color-dark;
    }
  }
}
.btn-outline-danger {
  @include color-mode(dark) {
    color: lighten($red, 20%);
    border-color: lighten($red, 20%);

  }
}
.btn-primary {
  @include color-mode(dark) {
    background-color: $blue-700;
    border-color: $blue-700;

  }
}
.btn-simplelink {
  display: inline;
  padding: 0;
  vertical-align: baseline;
  border: 0;
  line-height: inherit;
  background: transparent;
  font: inherit;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;

  color: $btn-link-color;
  text-decoration: $link-decoration;
  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &:focus {
    text-decoration: $link-hover-decoration;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  &.nav-link {
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
  }
}
.btn-icon {
  padding: 0;
  .icon {
    width: 1.5em;
    height: 1.5em;
  }
}
.btn-cta {
  color: white;
  font-weight: normal;
  text-transform: uppercase;
  border-radius: 200px;
  background: $mainColor;
  border: 1px solid $mainColor;
  padding: 0.75em 1.75em;
  &.btn-cta__secondary.btn {
    background: transparent;
    border: 1px solid white;
    color: white;
    &:hover {
      background-color: rgba(0,0,0,0.25);
    }
  }
  &:active, &:focus,
  &:hover {
    color: white;
    background-color: $mainColorDarkest;
    border-color: $mainColorDarkest;
  }
}
.bg-theme {
  background-color: $mainColor !important;
}
.bg-body {
  background-color: white !important;
  @include color-mode(dark) {
    background-color: $gray-900 !important;
  }
}
.text-theme {
  color: $mainColor !important;;
}
.text-small {
  font-size: 0.875em;
}
.text-tiny {
  font-size: 0.75em;
}
.text-dark {
  @include color-mode(dark) {
    color: $gray-200 !important;
  }
}
.text-secondary {
  @include color-mode(dark) {
    color: $gray-400 !important;
  }
}
.text-disabled {
  color: $gray-600;
  @include color-mode(dark) {
    color: $gray-400;
  }
}
.btn-outline-dark.btn-disabled {
  background-color: $gray-200;
  color: $gray-600;
  border-color: $gray-600;
  cursor: auto;
}
.table-datatable .btn-outline-secondary {
  background-color: white;
}
@include color-mode(dark) {
  .btn-outline-secondary {
    color: $gray-300;
    border-color: $gray-300;
    &:hover {
      background-color: $gray-800;
    }
  }
  .table-datatable .btn-outline-secondary {
    background-color: $gray-900;
  }
  .btn-outline-dark {
    border-color: $gray-200;
    color: $gray-200;
    &.btn-disabled {
      background-color: $gray-700;
      color: $gray-400;
      border-color: $gray-400;
      .badge {
        color: $gray-400 !important;
      }
    }
  }
}
.badge {
  &.bg-primary {
    background-color: $mainColor !important;
    @include color-mode(dark) {
      background-color: $mainColorDark !important;
    }
  }
  &.bg-light {
    background-color: $gray-200;
    color: $gray-800;
    @include color-mode(dark) {
      background-color: $gray-800;
      color: $gray-200;
    }
  }
  &.normal-size {
    font-size: 1em;
    padding: 0.3em 0.7em;
  }
  &.big {
    font-size: 0.8em;
    padding: 0.3em 0.7em;
  }
  &.spaced {
    margin-right: 0.4em;
  }
  &.middle {
    vertical-align: middle;
  }
  &.epic {
    border: 1px solid;
  }
  &.break-line {
    white-space: normal;
  }
  &.with-border {
    border: 1px solid;
  }
  &.border {
    font-weight: 400;
    background-color: white;
    @include color-mode(dark) {
      background-color: black;
    }
  }
  @include color-mode(dark) {
    &.bg-white,
    &.bg-light {
      background-color: black !important;
      color: white !important;
    }
  }
}
.badge-priority-undefined {
  color: $gray-500;
}
.badge-priority-highest {
  color: $red;
}
.badge-priority-high {
  color: $orange;
}
.badge-priority-medium {
  color: scale-color($yellow, $lightness: -20%);;
}
.badge-priority-low {
  color: $lime;
}
.badge-priority-lowest {
  color: $green;
}
.badge[class*="badge-priority"] {
  border: 1px solid $gray-400;
  background-color: white;
  @include color-mode(dark) {
    border-color: $gray-600;
    background-color: black;
  }
}
.text-yellow {
  color: darken($yellow, 15%);
}
.text-green {
  color: darken($green, 5%);
}
.text-teal {
  color: $teal;
}
.text-red {
  color: $red
}
.text-gray {
  color: $gray-600;
}
.text-orange {
  color: $orange;
}

.messages-container {
  position: fixed;
  top: 2.5em;
  right: 1em;
  z-index: $zindex-popover;
}

.ck.ck-content pre {
  color: white;
  background-color: $gray-800;
  border-color: $gray-600;
}
.ck.ck-editor__editable_inline {
  margin: 1em 0;
  border: 0;
  background-color: $body-bg;
  min-height: 7em;
  &.ck-focused {
    background-color: white;
    @include color-mode(dark) {
      background: $gray-900;
    }
  }
  @include color-mode(dark) {
    background: $body-bg-dark;
  }
}
.ck-body .ck.ck-balloon-panel {
  z-index: $zindex-tooltip;
}
.ck-body .ck.ck-balloon-panel.ck-balloon-panel_toolbar_west {
  z-index: $zindex-popover;
}
.ck-list .ck.ck-list__item .ck-button.ck-on {
  background-color: $blue-400;
}
.ck.ck-content .mention {
  color: $mainColor;
  background-color: adjust-color($color: $mainColor, $alpha: -0.9);
  @include color-mode(dark) {
    color: $mainColorDarkmode;
    background-color: adjust-color($color: $mainColorDarkmode, $alpha: -0.8);
  }
}

.dropdown.is-invalid button {
  border-color: $form-feedback-invalid-color;
}
.dropdown.no-carret {
  .dropdown-toggle::after {
    display: none;
  }
}

.breadcrumb {
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    .breadcrumb-item {
      display: none;
      &:nth-last-child(2),
      &:last-child {
        display: block;
      }
    }

  }
}

.modal-backdrop.upper-modal {
  z-index: $zindex-modal-backdrop2;
}
.modal.upper-modal {
  z-index: $zindex-modal2;
}
.modal {
  @include color-mode(dark) {
    --bs-modal-bg: #{$body-bg-dark};
  }
}

.fs-reset {
  font-size: 1rem;
}

.btn-group.btn-group-vertical-mobile {
  @include media-breakpoint-down(sm) {
    display: block;
    & > * {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
    & > .btn:first-child {
      border-top-right-radius: .25em;
      border-top-left-radius: .25em;
      border-bottom-left-radius: 0;
    }
    & > .btn:last-child {
      border-bottom-right-radius: .25em;
      border-top-right-radius: 0;
      border-bottom-left-radius: .25em;
    }
    & > .btn:not(:first-child) {
      margin-left: 0;
      margin-top: -1px;
    }
  }
}

.table {
  &.auto {
    width: auto;
    th:not(:first-child),
    td:not(:first-child) {
      padding-left: 0.7em;
    }
    th:not(:last-child),
    td:not(:last-child) {
      padding-right: 0.7em;
    }
  }
  & > :not(:first-child) {
    border-width: 0;
  }
  thead {
    border-bottom: 1px solid black;
    @include color-mode(dark) {
      border-bottom: 1px solid white;
    }
  }
}
.table {
  --bs-table-bg: transparent;
}
.table-light {
  @include color-mode(dark) {
    --bs-table-bg: #{$gray-800};
    --bs-table-color: #{$body-color-dark};
  }
}
.table-danger {
  @include color-mode(dark) {
    --bs-table-bg: #{$bgRedDark};
    --bs-table-color: white;
  }
}
@include media-breakpoint-down(sm) {
  .table-sm-responsive {
    display: block;
    tbody {
      display: block;
    }
    thead {
      display: block;
    }
    thead tr {
      display: flex;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      flex-flow: row wrap;
      td, th {
        border: 0;
        padding-top: 0;
        padding-bottom: 0.25em;
      }
    }
    tbody tr {
      display: flex;
      flex-flow: row wrap;
      border-bottom: 1px solid var(--bs-table-border-color);
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      td {
        padding-top: 0;
        padding-bottom: 0;
        border: 0;
        width: 50%;
        white-space: nowrap;
        &.text-end {
          text-align: left !important;
        }
        &::before {
          content: attr(data-title) ': ';
        }
        &.no-title::before {
          display: none;
        }
      }
    }
  }
}


.nav-tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    background: none;
    border: 0;
    border-bottom: $nav-tabs-border-width solid transparent;
    &:hover,
    &:focus {
      background-color: $gray-200;
      @include color-mode(dark) {
        background-color: $gray-800;
      }
    }
  }
}

pre {
  font-size: 1em;
  font-family: inherit;
  white-space: pre-wrap;
  margin: 0;
}

.block-title {
  @include block-title;
}

.pagination {
  .icon {
    width: 16px;
    height: 16px;
  }
  .prevnext {
    padding: 0em 0.3em 0.1em;
  }
  &--mini {
    .page-link {
      background: transparent;
      margin-left: -1px;
      border-color: $gray-500;
      &:hover {
        background-color: $body-bg;
        @include color-mode(dark) {
          background-color: black;
        }
      }

    }
    li:first-child {
      .page-link {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

    }
    li:last-child {
      .page-link {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

    }
  }
}
