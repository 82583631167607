@mixin color-mode($mode: light, $root: false) {
  @if $color-mode-type == "media-query" {
    @if $root == true {
      @media (prefers-color-scheme: $mode) {
        :root {
          @content;
        }
      }
    } @else {
      @media (prefers-color-scheme: $mode) {
        @content;
      }
    }
  } @else {
    @at-root [data-bs-theme="#{$mode}"] #{&} {
      @content;
    }
  }
}

@mixin block-title {
  font-size: 1.25em;
  color: $secondaryColor;
  font-weight: normal;
  @include color-mode(dark) {
    color: $secondaryColorLight;
  }
}
@mixin caret-base {
  width: $caret-width;
  height: $caret-width;
  border-top: 1px solid;
  border-right: 1px solid;
  border-bottom: 0;
  border-left: 0;
  transform-origin: 50% 50%;
  transition: transform 0.2s ease;
}

@mixin caret-down($width: $caret-width) {
  @include caret-base();
  transform: translateY($caret-width / -2) rotate(135deg);
}

@mixin caret-up($width: $caret-width) {
  @include caret-base();
  transform: translateY($caret-width / 2) rotate(-45deg);
}

@mixin caret-end($width: $caret-width) {
  @include caret-base();
  transform: translateX($caret-width / -2) rotate(45deg);
}

@mixin caret-start($width: $caret-width) {
  @include caret-base();
  transform: translateX($caret-width / 2) rotate(-135deg);
}