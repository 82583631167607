@import "variables";
@import "bootstrap-imports";
@import "mixins";
@import "theme";
@import "layout/base";
@import "layout/frontpages";
@import "layout/navbar";
@import "layout/sidebar";
@import "layout/topmenu";
@import "page";
@import "pages";
@import "form";
@import "loader";
@import "datatable";
@import "filters";
@import "stats";
@import "products";
@import "history";
@import "kanban";
@import "item-status";
@import "backlogitem";
@import "worklogs";
@import "image-viewer";
@import "graph";
@import "autocomplete";
@import "roadmap";
@import "helpscout";
@import "gitlab";
@import "color";
@import "components/components";

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
