.datatable {
  .col__actions {
    text-align: right;
    .btn {
      margin-left: 0.5em;
    }
    .dropdown .btn {
      margin-left: 0;
    }
  }
  tbody {
    td {
      vertical-align: middle;
    }
  }
  .search {
    width: 15em;
    vertical-align: bottom;
    .form-control {
      font-size: 1em;
      line-height: 1.5;
      height: auto;
      min-height: 0;
      padding: 0.375rem 0.75rem;
      &:focus ~ label,
      &:not(:placeholder-shown) ~ label {
        color: $body-color;
        transform: scale(0.85) translateY(-2.1em) translateX(-0.75em);
        &::after {
          display: none;
        }
      }
      @include color-mode(dark) {
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
          color: $body-color-dark;
        }
      }
    }
    .btn-dark {
      @include color-mode(dark) {
        border-color: $border-color-dark;
        color: $body-color;
        background-color: $body-bg;
      }
    }
  }
  .datatable-table-container {
    overflow-x: auto;
  }
  td[data-key="epic"],
  td[data-key="item_type"] {
    white-space: nowrap;
    .color-pill {
      vertical-align: middle;
      padding: 0.35em;
    }
    .label {
      display: inline-block;
      margin-left: 0.25em;
      white-space: normal;
      vertical-align: middle;
    }
  }
}
.table-datatable-head {
  padding-top: 1.25em;
  margin-bottom: 0.5em;
  position: relative;
  .dropdown {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
    & + strong {
      margin-right: 1em;
    }
  }
  .table-pagination {
    text-align: right;
    align-self: center;
    strong {
      vertical-align: middle;
      margin-right: 1em;
    }
    ul.pagination {
      display: inline-flex;
      margin: 0;
      vertical-align: middle;
      margin-left: 0.75em;
    }
    & > .btn {
      font-size: 0.875em;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      margin: 0.5em 0;
      padding: 5px;
      border: 1px solid $gray-500;
    }
  }
  .filter-labelled {
    position: relative;
    margin-right: 0.8em;
    padding-top: 1.3em;
    vertical-align: bottom;
    min-width: 7em;
    label {
      position: absolute;
      bottom: 100%;
      left: 0;
      font-size: 0.875em;
      padding-bottom: 0.1em;
      margin-bottom: -1.3em;
      line-height: 1.1;
    }
  }
  .filter-autocomplete {
    width: 12em;
    .form-control {
      width: 100%;
    }
  }
  .filters-more {
    position: absolute;
    margin-top: 0.5em;
    top: 100%;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: white;
    z-index: 11;
    box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
    border: 1px solid $input-border-color;
    @include color-mode(dark) {
      background-color: $body-bg-dark;
    }
    .filter-foreign-key, .filter-autocomplete, .filter-checkboxes {
      vertical-align: top;
      margin-bottom: 0.5em;
      margin-right: 1em;
    }
    .filter-checkbox {
      vertical-align: top;
      margin-top: 1.5em;
      margin-right: 1em;
    }
    .filter-checkboxes {
      .label {
        font-size: 0.875em;
      }
    }
    .filters .btn {
      margin-top: 0.7em;
    }
  }
  .select-items-by-page {
    display: inline-block;
    width: auto;
  }
}
.modal-datatable-filters {
  .dropdown {
    margin: 1em 0;
    button {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      align-items: center;
      &::after {
        margin-left: auto;
      }
    }
  }
  .filter-autocomplete {
    width: 100%;
    label, .autocomple-input-wrapper {
      display: block;
    }
    input.form-control {
      width: 100%;
    }
  }
  .filter-foreign-key, .filter-autocomplete, .filter-checkboxes, .filter-checkbox {
    margin: 1em 0;
    display: block!important;
  }
  .search {
    margin: 1em 0;
  }
}

@include media-breakpoint-down(sm) {
  .datatable .datatable-table-container {
    overflow-x: visible;
  }
  .table-responsive {
    overflow-x: visible;
    display: block;
    tr, td, th, tbody, thead, tfoot {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      padding: 0.2em 0;
      border: 0;
    }
    tr {
      padding: 0.5em 0;
      border-bottom: 1px solid $gray-500;
    }
    td::before {
      content: attr(data-label) ":";
      font-size: 0.8em;
      color: $gray-600;
      margin-right: 0.4em;
    }
    td.responsive-flex {
      display: flex;
      align-items: center;
      & > * {
        flex-grow: 1;
      }
      &::before {
        flex-grow: 0;
        flex-shrink: 1;
        display: block;
      }
    }
    .col__actions {
      &::before {
        display: none;
      }
      &>:first-child {
        margin-left: 0;
      }
      text-align: left;
    }
    &.table-checkboxes {
      td {
        margin-left: 1.5em;
      }
      td.select-row {
        margin: 0;
        float: left;
        &::before {
          display: none;
        }
      }
    }
  }
}
