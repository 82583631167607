.job-html {
  & > ul {
    list-style: none;
    display: block;
    padding: 0;
    & > li {
      margin: 0;
      padding: .75rem 1.25rem;
      border: 1px solid var(--bs-border-color);
      margin-bottom: -1px;
      display: flex;
      flex-flow: row nowrap;
      &:first-child {
        border-top-left-radius: var(--bs-border-radius-sm);
        border-top-right-radius: var(--bs-border-radius-sm);
      }
      &:last-child {
        border-bottom-left-radius: var(--bs-border-radius-sm);
        border-bottom-right-radius: var(--bs-border-radius-sm);
      }
      & > ul {
        margin-left: auto;
        width: 75%;
        list-style: disc;
      }
    }
  }
}