.graph {
  background: white;
  padding: 1em;
  position: relative;
  margin: 1em 0;
  @include color-mode(dark) {
    background-color: $gray-900;
  }
  &__inside {
    position: relative;
    padding-bottom: 2em;
    padding-left: 2em;
  }
  &__xaxis {
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
  &__yaxis {
    position: absolute;
    left: 0.5em;
    text-align: center;
    top: 50%;
    transform: rotate(-90deg) translateX(-30%);
    white-space: nowrap;
    transform-origin: 0% 50%;
  }
  &__legend {
    text-align: center;
  }
  &__error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .dropdown-toggle {
    padding-left: 0.2em;
    padding-right: 0.2em;
    &::after {
      display: none;
    }
  }
}
.graph-burndown, .graph-burnup, .graph-velocity {
  .graph__legend {
    .bar {
      display: inline-block;
      vertical-align: middle;
      border-top: 2px dotted #666;
      margin-right: 0.5em;
      width: 1em;
      font-size: 0.75em;
      &.bar-2 {
        border-top: 2px solid $mainColor;
      }
    }
    .bar-square {
      display: inline-block;
      vertical-align: text-bottom;
      margin-right: 0.5em;
      width: 1.25em;
      height: 1.25em;
      border: 1px solid;
    }
  }
}

.content-block .graph {
  padding: 0;
}

.graph-burndown {
  .st1 {
    fill: transparent;
    stroke: #666;
  }
  .st2 {
    fill: transparent;
    stroke: #333;
  }
  .st3 {
    fill: transparent;
    stroke: #cf1452;
    stroke-width: 2;
  }
  @include color-mode(dark) {
    .st1 {
      stroke: #ccc;
    }
    .st2 {
      stroke: #ccc;
    }
    .st3 {
      stroke: $mainColorLight;
    }
    .graph__legend {
      .bar {
        border-top: 2px dotted #ccc;
        &.bar-2 {
          border-top: 2px solid $mainColorLight;
        }
      }
    }
  }
}
.graph-burnup {
  .st1 {
    fill: transparent;
    stroke: #666;
  }
  .st2 {
    fill: transparent;
    stroke: #333;
  }
  .st-scope {
    fill: transparent;
    stroke: $blue;
    stroke-width: 2;
  }
  .st-todo {
    fill: transparent;
    stroke: $red;
    stroke-width: 2;
  }
  .st-done {
    fill: transparent;
    stroke: $green;
    stroke-width: 2;
  }
  .bar {
    &.bar-scope {
      border-top: 2px solid $blue;
    }
    &.bar-todo {
      border-top: 2px solid $red;
    }
    &.bar-done {
      border-top: 2px solid $green;
    }
  }
  @include color-mode(dark) {s
    .st1 {
      stroke: #ccc;
    }
    .st2 {
      stroke: #ccc;
    }
    .st-scope {
      stroke: lighten($blue, 30%);
    }
    .st-todo {
      stroke: lighten($red, 30%);
    }
    .st-done {
      stroke: lighten($green, 30%);
    }
    .graph__legend {
      .bar {
        border-top: 2px dotted #ccc;
        &.bar-scope {
          border-top: 2px solid lighten($blue, 30%);
        }
        &.bar-todo {
          border-top: 2px solid lighten($red, 30%);
        }
        &.bar-done {
          border-top: 2px solid lighten($green, 30%);
        }
      }
    }
  }
}
.graph-velocity {
  .graph__graph {
    padding-top: 10px;
  }
  .graph__inside {
    padding-bottom: 0;
  }
  .xlabel {
    font-size: 0.875em;
    text-align: center;
    padding: 0.25em 0.5em;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .yvalue {
    text-anchor: middle;
    font-size: 0.75em;
  }
  .st1 {
    fill: none;
  }
}
