.topmenu {
  position: fixed;
  top: 0;
  right: 2em;
  z-index: $zindex-fixed + 1;
  background: white;
  box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 5px;
  height: 2.5em;
  .bt-theme {
    margin-right: 0.75em;
    margin-left: 0.25em;
    margin-top: -0.52em;
    svg {
      display: block;
    }
  }
  @include color-mode(dark) {
    background: #111;
    .dropdown-toggle.nav-link {
      color: white;
    }
    .bt-theme {
      color: white;
    }
  }
  @include media-breakpoint-down(lg) {
    background: transparent;
    right: 60px;
    @include color-mode(dark) {
      background: transparent;
    }
    .dropdown-toggle.nav-link {
      color: white;
    }
  }
  @include media-breakpoint-down(md) {
    .language-dropdown,
    .bt-theme {
      display: none;
    }
    .notifications-dropdown {
      align-self: center;
      & > button {
        border: 1px solid $mainColorLight;
        background-color: $mainColorDarker;
        border-radius: $btn-border-radius-sm;
        padding: 5px 8px;
        margin-right: 10px;
        &::after {
          display: none;
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .notifications-dropdown .dropdown-menu {
      background-color: $mainColorDarkestDeep;
      width: 100vw;
    }
  }
}

.body-home .topmenu {
  right: 0;
  .language-dropdown {
    display: block;
  }
}

.user-dropdown {
  .nav-link {
    padding: 0.75em;
    color: white;
    background-color: $mainColor;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 2px 3px 4px rgba(0,0,0,0.2);
    &:after {
      display: none;
    }
  }
}

.notifications-dropdown {
  .dropdown-menu {
    width: 460px;
    max-height: 380px;
    overflow: auto;
    background-color: white;
    @include color-mode(dark) {
      background-color: $gray-800;
    }
  }
  .card {
    font-size: 0.875em;
    .hstack {
      margin: 0.3em 0;
      align-items: flex-start;
    }
  }
}