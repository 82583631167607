.filters {
   :not(.btn-group-vertical) > .btn {
    font-size: 0.875em;
    padding: 0.3em 0.7em 0.35em;
    margin-right: 0.25em;
    margin-bottom: 5px;
    .icon {
      width: 1em;
      height: 1em;
      margin-left: 0.25em;
    }
  }
  .btn-link {
    text-decoration: none;
    color: $mainColor;
    border: 1px solid $mainColor;
  }
  .filter-foreign-key {
    margin-right: 0.6em;
  }
}
