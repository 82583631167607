.storymap-filters {
  .form-check {
    display: inline-block;
    margin-right: 1em;
  }
}

.story-map-row {
  display: flex;
  flex-flow: row nowrap;
}
.story-map-cell {
  box-sizing: border-box;
  width: 150px;
  display: flex;
  flex-flow: column;
  text-align: right;
  .add-btn {
    align-self: flex-end;
    padding: 3px 6px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  &.no-activity {
    background-color: $gray-100;
  }
}
@include color-mode(dark) {
  .story-map-cell.no-activity {
    background-color: $gray-800;
  }
}
.story-map-table {
  overflow-x: auto;
  overflow-y: visible;
  padding-bottom: 7em;
}
.story-map-head {
  .story-map-personas, .story-map-goals, .story-map-activities {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    & > * {
      min-width: 200px;
      box-sizing: border-box;
      flex-shrink: 0;
    }
    
  }
  .story-map-activities {
    padding-bottom: 5px;
  }
  .story-map-personas {
    &__header {
      .btn {
        display: block;
        margin-bottom: 5px;
        width: 185px;
      }
    }
    &.is-drag-over {
      background-color: lighten($bgYellow, 20%);
      @include color-mode(dark) {
        background-color: darken($bgYellowDark, 10%);
      }
    }
  }
  .story-map-goals.is-drag-over {
    background-color: lighten($bgOrange, 16%);
    @include color-mode(dark) {
      background-color: darken($bgOrangeDark, 5%);
    }
  }
  .story-map-activities.is-drag-over {
    background-color: lighten($bgRed, 16%);
    @include color-mode(dark) {
      background-color: darken($bgRedDark, 5%);
    }
  }
  .story-map-card {
    margin-left: 5px;
    .story-map-card__body {
      font-size: 0.875em;
      height: 2.8em;
      box-sizing: content-box;
    }
    .field-update {
      &.view {
        .icon {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 3;
        }
        .name {
          
          padding-right: 1em;
          display: -webkit-box;
          overflow: hidden;
          height: 2.8em;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          background-color: $content-block-bg;
          @include color-mode(dark) {
            background-color: $content-block-bg-dark;
          }
        }
        &:hover, &:focus {
          .name {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: auto;
            display: block;
            z-index: 2;
          }
        }
      }
    }
  }
}
.story-map-body {
  &.is-drag-over {
    background-color: lighten($bgCyan, 20%);
    @include color-mode(dark) {
      background-color: darken($bgCyanDark, 7%);
    }
  }
}
.story-map-release {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 5px;
  padding-top: 5px;
  border-top: 1px solid black;
  & > * {
    min-width: 200px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  @include color-mode(dark) {
    border-color: white;
  }
}
.story-map-card {
  background-color: $content-block-bg;
  border: 1px solid $content-block-border-color;
  border-radius: 4px;
  box-sizing: border-box;
  width: 185px;
  position: relative;
  &__header {
    padding: 5px;
    line-height: 1;
    & > span {
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &.hidden .story-map-card__header {
    color: $gray-600;
    background-color: $bgGrey;
    @include color-mode(dark) {
      color: $gray-400;
      background-color: $bgGreyDark;
    }
  }
  &__body {
    padding: 5px;
  }
  .dropdown {
    position: absolute;
    top: -3px;
    right: 0px;
  }
  .field-update {
    display: block;
    &.view {
      .icon {
        margin-left: 0.5em;
      }
    }
    &.edit {
      height: 2.8em;
      .field-value {
        display: none;
      }
    }
    .form-control {
      border: 1px solid $gray-500;
    }
    
    .form-group {
      right: -8em;
      margin: 0;
      display: block;
      z-index: 3;
      .input-wrapper {
        display: block;
      }
      button {
        background-color: white;
        margin-left: 0.1em;
        padding: 0.1em;
        @include color-mode(dark) {
          background-color: black;
        }
      }
    }
    button[type=submit] {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
    }
    button[type=button] {
      position: absolute;
      top: 50%;
      left: 100%;
      margin-left: 1.7em;
      transform: translateY(-50%);
    }
  }
  &--task {
    margin-bottom: 5px;
    padding: 10px 5px;
    .field-update {
      font-size: 0.875em;
      padding-top: 1em;
    }
    .linked-item {
      position: absolute;
      top: 0;
      right: 2.2em;
    }
  }
  @include color-mode(dark) {
    background-color: $content-block-bg-dark;
    border-color: $content-block-border-color-dark;
  }
}
.story-map-tasks {
  min-height: 1.6em;
  padding: 0px 5px;
  flex-grow: 1;
  text-align: left;
}
