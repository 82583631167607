.worklogs-page {
  .menu-date-select {
    padding: 0.4em 0.4em 1em;
    width: 280px;
    .input-wrapper {
      display: flex;
      align-items: center;
      margin-top: 0.6em;
      label {
        flex-grow: 1;
      }
    }
    .btn {
      font-size: 1em;
      margin-right: 0;
    }
  }
  .dropdown-toggle.btn {
    font-size: 1em;
    margin-right: 0;
  }
  .menu-date-select-header {
    text-align: center;
    padding: 0.3em;
    background-color: $gray-100;
    @include color-mode(dark) {
      background-color: $gray-800;
    }
    white-space: nowrap;
  }

}

.worklogs-table {
  display: flex;
  flex-flow: row nowrap;
  .we {
    background-color: $gray-100;
    @include color-mode(dark) {
      background-color: $gray-800;
    }
  }
  .total-bottom {
    td, th {
      border-top: 2px solid $gray-400;
      @include color-mode(dark) {
        border-top: 2px solid $gray-600;
      }
    }
  }
  .table {
    margin-bottom: 0;
  }
  thead {
    background-color: white;
    @include color-mode(dark) {
      background-color: $gray-900;
    }
  }
  &__header {
    white-space: nowrap;
    thead {
      position: sticky;
      top: 0;
    }
  }
  &__data {
    overflow: auto;
    white-space: nowrap;
    position: relative;
    table {
      table-layout: fixed;
    }
    td {
      border-left: 1px solid $gray-200;
      @include color-mode(dark) {
        border-left: 1px solid $gray-700;
      }
    }
    th:first-child, td:first-child {
      width: 6em;
    }
  }
  &__date {
    th {
      text-align: center;
    }
    td, th {
      width: 3.5em;
    }
  }
  &__month {
    thead {
      th {
        text-align: center;
        font-weight: normal;
        line-height: 1.2;
      }
      .year {
        font-size: 0.875em;
        display: block;
        
      }
    }
    td, th {
      width: 3em;
    }
  }
}