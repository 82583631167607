.org-form-page__new_1 {
  .content-block {
    padding: 0 0.7em!important;
    background-color: transparent!important;
  }
}

// registration
.form-subscription {
  .sub-filters {
    text-align: center;
    .form-select {
      width: auto;
    }
    .form-label {
      margin: 0 0.75em;
    }
  }
}
.card__sub {
  position: relative;
  margin-top: 2em;
  width: 32.5%;
  &.inactive {
    opacity: 0.65;
  }
  .btn {
    margin: 1em 0;
  }
  .subtitle {
    position: static;
    margin-left: 2em;
    margin-right: 2em;
    color: white;
    padding: 0.5em 1em;
    text-align: center;
    transform: translateY(-50%);
    @include media-breakpoint-down(xl) {
      margin-left: 1em;
      margin-right: 1em;
    }
  }
  .card-title {
    text-align: center;
    font-size: 1.5em;
    font-weight: normal;
    margin: 0 0 0.875em 0;
  }
  .card-body {
    padding-top: 1.5em;
    text-align: center;
  }
  span.price {
    font-size: 2em;
  }
  @include media-breakpoint-down(md) {
    width: 49%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.card__trial {
  width: auto;
  margin-top: 0;
  .card-title {
    display: inline-block;
    margin: 0em 0.75em 0em 0;
    vertical-align: middle;
  }
  .btn {
    margin: 0;
    vertical-align: middle;
  }
}
.card__starter {
  border-color: $secondaryColor;
  .subtitle {
    background-color: $secondaryColor;
  }
  .card-title {
    color: $secondaryColor;
  }
  .btn {
    color: white;
    border-color: white;
    background-color: $secondaryColor;
    &:hover {
      color: white;
      background-color: darken($secondaryColor, 9%);
    }
  }
}
.card__premium {
  border-color: $mainColorDark;
  .subtitle {
    background-color: $mainColorDark;
  }
  .card-title {
    color: $mainColorDark;
  }
  .btn {
    color: white;
    border-color: white;
    background-color: $mainColorDark;
    &:hover {
      color: white;
      background-color: darken($mainColorDark, 9%);
    }
  }
}
.card__entreprise {
  border-color: $blue;
  .subtitle {
    background-color: $blue;
  }
  .card-title {
    color: $blue;
  }
}

.sprints-list {
  .links {
    & > * {
      margin-right: 0.75em;
    }
  }
  .sprint {
    padding: 0.75em 1em;
    border-bottom: 1px solid $border-color;
    @include color-mode(dark) {
      border-bottom: 1px solid $gray-700;
    }
    &__active {
      border: 1px solid $mainColor;
    }
    &__closed {
      background-color: $gray-100;
      color: $gray-600;
      @include color-mode(dark) {
        background-color: $gray-900;
        color: $gray-400;
      }
    }
  }
}