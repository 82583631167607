.bulk-items-modal {
  th {
    width: 20%;
  }
  th.table--settings {
    width: 60%;
  }
  .form-group {
    margin-bottom: 0;
  }
  .select-search {
    display: inline-block;
  }
  .autocomplete-edit-area {
    margin: 0.25em 0;
    label {
      margin-right: 1em;
    }
  }
  .form-select {
    width: auto;
  }
  .form-control[type="text"] {
    width: 12em;
    flex-grow: 0;
  }
  .form-control[type="number"] {
    width: 6em;
    flex-grow: 0;
  }
  .form-control[type="date"] {
    width: 12em;
    flex-grow: 0;
  }
}