.autocomplete {
  .bt-active-item {
    .icon {
      color: inherit;
    }
  }
  .bt-add-item {
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.autocomplete-continuous {
    @extend .form-control;
    padding-right: 2em;
    .bt-active-item {
      background-color: white;
      &:hover {
        border-color: $danger;
        color: $danger;
      }
    }
    .autocomplete-edit-area {
      display: inline-block;
      max-width: 100%;
      width: 5em;
    }
    .bt-add-item {
      padding: 5px;
      svg {
        margin: 0;
      }
    }
    .autocomple-input-wrapper {
      .loader {
        right: -30px;
      }
      input {
        padding-right: 0.75rem;
      }
    }
    &.autocomplete-autocreate {
      .autocomple-input-wrapper {
        input {
          padding-right: 2em;
        }
      }
    }
    @include color-mode(dark) {
      .bt-add-item svg {
        color: $gray-200;
      }
      .bt-active-item {
        background-color: $gray-900;
        &:hover {
          border-color: $danger-text-emphasis-dark;
          color: $danger-text-emphasis-dark;
        }
      }
    }
  }
}