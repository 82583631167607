.organization-list,
.products-list,
.backlog-items-list,
.integration-list {
  .card {
    margin-bottom: 1em;
  }
  &__empty {
    .card-body {
      text-align: center;
      padding-top: 2em;
      padding-bottom: 2em;
    }
  }
  @include media-breakpoint-up(sm) {
    &.columns-3 {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      .card {
        width: 32%;
        margin-right: 2%;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    &.columns-4,
    &.columns-2 {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      .card {
        width: 48.5%;
        margin-right: 3%;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &.columns-4 {
      .card {
        width: 23.5%;
        margin-right: 2%;
        &:nth-child(2n) {
          margin-right: 2%;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

}

.backlog-items-list {
  .card {
    margin-bottom: 0.5em;
  }
}

.organization__teaser,
.product__teaser,
.item__teaser,
.integration__teaser {
  .icon {
    color: $mainColor;
    float: left;
  }
  a .icon {
    color: $gray-600;
  }
  .card-content {
    margin-left: 2.5em;
  }
}
.product__teaser {
  background-color: transparent;
}

.integration__teaser:hover {
  background-color: $mainColorLight;
}
