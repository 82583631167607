.stats {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .stat:nth-child(1) {
    background-color: $mainColorDarker;
  }
  .stat:nth-child(2) {
    background-color: #644d6d;
  }
  .stat:nth-child(3) {
    background-color: #546c84;
  }
  .stat:nth-child(4) {
    background-color: $secondaryColor;
  }
  .stat {
    width: 48.5%;
    h4 {
      font-size: 1em;
      margin: 0;
    }
    hr {
      margin: 0.3em 0;
    }
    .count {
      font-size: 3em;
      line-height: 1;
    }
    .link {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &.no-cols {
    display: block;
    .stat {
      width: auto;
    }
  }
}