.color-pill {
  display: inline-block;
  border: 1px solid $gray-700;
  padding: 0.5em;
  display: inline-block;
  border-radius: 3px;
  vertical-align: middle;
  margin-right: 0.25em;
}

.dropdown-menu-colors {
  column-count: 3;
}

.bg-col-blue {
  border-color: $bgBlueDark;
  color: $bgBlueDark;
  background-color: $bgBlue;
}
.bg-col-indigo {
  border-color: $bgIndigoDark;
  color: $bgIndigoDark;
  background-color: $bgIndigo;
}
.bg-col-purple {
  border-color: $bgPurpleDark;
  color: $bgPurpleDark;
  background-color: $bgPurple;
}
.bg-col-pink {
  border-color: $bgPinkDark;
  color: $bgPinkDark;
  background-color: $bgPink;
}
.bg-col-carmine {
  border-color: $bgCarmineDark;
  color: $bgCarmineDark;
  background-color: $bgCarmine;
}
.bg-col-red {
  border-color: $bgRedDark;
  color: $bgRedDark;
  background-color: $bgRed;
}
.bg-col-orange {
  border-color: $bgOrangeDark;
  color: $bgOrangeDark;
  background-color: $bgOrange;
}
.bg-col-yellow {
  border-color: $bgYellowDark;
  color: $bgYellowDark;
  background-color: $bgYellow;
}
.bg-col-lime {
  border-color: $bgLimeDark;
  color: $bgLimeDark;
  background-color: $bgLime;
}
.bg-col-green {
  border-color: $bgGreenDark;
  color: $bgGreenDark;
  background-color: $bgGreen;
}
.bg-col-teal {
  border-color: $bgTealDark;
  color: $bgTealDark;
  background-color: $bgTeal;
}
.bg-col-cyan {
  border-color: $bgCyanDark;
  color: $bgCyanDark;
  background-color: $bgCyan;
}
.bg-col-cyan {
  border-color: $bgCyanDark;
  color: $bgCyanDark;
  background-color: $bgCyan;
}
.bg-col-white {
  border-color: $bgGreyDark;
  color: $bgGreyDark;
  background-color: white;
}
.bg-col-grey {
  border-color: $bgGreyDark;
  color: $bgGreyDark;
  background-color: $bgGrey;
}
@include color-mode(dark) {
  .bg-white {
    background-color: $black !important;
  }
  .bg-light {
    background-color: $gray-800 !important;
    color: $gray-200 !important;
  }
  .bg-col-blue {
    border-color: $bgBlue;
    color: $bgBlue;
    background-color: $bgBlueDark;
  }
  .bg-col-indigo {
    border-color: $bgIndigo;
    color: $bgIndigo;
    background-color: $bgIndigoDark;
  }
  .bg-col-purple {
    border-color: $bgPurple;
    color: $bgPurple;
    background-color: $bgPurpleDark;
  }
  .bg-col-pink {
    border-color: $bgPink;
    color: $bgPink;
    background-color: $bgPinkDark;
  }
  .bg-col-carmine {
    border-color: $bgCarmine;
    color: $bgCarmine;
    background-color: $bgCarmineDark;
  }
  .bg-col-red {
    border-color: $bgRed;
    color: $bgRed;
    background-color: $bgRedDark;
  }
  .bg-col-orange {
    border-color: $bgOrange;
    color: $bgOrange;
    background-color: $bgOrangeDark;
  }
  .bg-col-yellow {
    border-color: $bgYellow;
    color: $bgYellow;
    background-color: $bgYellowDark;
  }
  .bg-col-lime {
    border-color: $bgLime;
    color: $bgLime;
    background-color: $bgLimeDark;
  }
  .bg-col-green {
    border-color: $bgGreen;
    color: $bgGreen;
    background-color: $bgGreenDark;
  }
  .bg-col-teal {
    border-color: $bgTeal;
    color: $bgTeal;
    background-color: $bgTealDark;
  }
  .bg-col-cyan {
    border-color: $bgCyan;
    color: $bgCyan;
    background-color: $bgCyanDark;
  }
  .bg-col-white {
    border-color: white;
    color: white;
    background-color: $bgGreyDark;
  }
  .bg-col-grey {
    border-color: $bgGrey;
    color: $bgGrey;
    background-color: $bgGreyDark;
  }
}

.chart-rect-col-cyan {
  stroke: $bgCyanDark;
  fill: $bgCyan;
}
.chart-rect-col-blue {
  stroke: $bgBlueDark;
  fill: $bgBlue;
}
.chart-rect-col-indigo {
  stroke: $bgIndigoDark;
  fill: $bgIndigo;
}
.chart-rect-col-purple {
  stroke: $bgPurpleDark;
  fill: $bgPurple;
}
.chart-rect-col-pink {
  stroke: $bgPinkDark;
  fill: $bgPink;
}
.chart-rect-col-carmine {
  stroke: $bgCarmineDark;
  fill: $bgCarmine;
}
.chart-rect-col-red {
  stroke: $bgRedDark;
  fill: $bgRed;
}
.chart-rect-col-orange {
  stroke: $bgOrangeDark;
  fill: $bgOrange;
}
.chart-rect-col-yellow {
  stroke: $bgYellowDark;
  fill: $bgYellow;
}
.chart-rect-col-lime {
  stroke: $bgLimeDark;
  fill: $bgLime;
}
.chart-rect-col-green {
  stroke: $bgGreenDark;
  fill: $bgGreen;
}
.chart-rect-col-teal {
  stroke: $bgTealDark;
  fill: $bgTeal;
}
.chart-rect-col-cyan {
  stroke: $bgCyanDark;
  fill: $bgCyan;
}
.chart-rect-col-cyan {
  stroke: $bgCyanDark;
  fill: $bgCyan;
}
.chart-rect-col-white {
  stroke: $bgGreyDark;
  fill: white;
}
.chart-rect-col-grey {
  stroke: $bgGreyDark;
  fill: $bgGrey;
}
@include color-mode(dark) {
  .chart-rect-col-cyan {
    stroke: $bgCyan;
    fill: $bgCyanDark;
  }
  .chart-rect-col-blue {
    stroke: $bgBlue;
    fill: $bgBlueDark;
  }
  .chart-rect-col-indigo {
    stroke: $bgIndigo;
    fill: $bgIndigoDark;
  }
  .chart-rect-col-purple {
    stroke: $bgPurple;
    fill: $bgPurpleDark;
  }
  .chart-rect-col-pink {
    stroke: $bgPink;
    fill: $bgPinkDark;
  }
  .chart-rect-col-carmine {
    stroke: $bgCarmine;
    fill: $bgCarmineDark;
  }
  .chart-rect-col-red {
    stroke: $bgRed;
    fill: $bgRedDark;
  }
  .chart-rect-col-orange {
    stroke: $bgOrange;
    fill: $bgOrangeDark;
  }
  .chart-rect-col-yellow {
    stroke: $bgYellow;
    fill: $bgYellowDark;
  }
  .chart-rect-col-lime {
    stroke: $bgLime;
    fill: $bgLimeDark;
  }
  .chart-rect-col-green {
    stroke: $bgGreen;
    fill: $bgGreenDark;
  }
  .chart-rect-col-teal {
    stroke: $bgTeal;
    fill: $bgTealDark;
  }
  .chart-rect-col-cyan {
    stroke: $bgCyan;
    fill: $bgCyanDark;
  }
  .chart-rect-col-white {
    stroke: white;
    fill: $bgGreyDark;
  }
  .chart-rect-col-grey {
    stroke: $bgGrey;
    fill: $bgGreyDark;
  }
}