.helpscout-page {
  .list-group-item {
    .card-body {
      padding: 0;
      text-align: left;
    }
    &:hover, &:focus {
      background-color: $gray-200;
    }
  }
}
