.page {
  h1.main-title {
    font-size: 1.5em;
    display: inline-block;
    background: transparent;
    color: $secondaryColor;
    font-weight: normal;
    padding: 0.3em 1em 0.3em 0.75em;
    margin: .75em 0 0;
    & + .breadcrumb-container {
      padding-left: 1.125em;
    }
    @include color-mode(dark) {
      color: $secondaryColorLight;
    }
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  &.sidebar-right {
    padding-right: 360px;
  }
  @include media-breakpoint-up(lg) {
    &.sidebar {
      padding-left: 300px;
      transition: padding-lef 0.3s ease;
    }
    &.sidebar-reduced {
      padding-left: 44px;
    }
    .alert--notifications-disabled {
      display: inline-block;
      margin: 0.5em;
    }
  }
  @include media-breakpoint-down(lg) {
    padding-top: 50px;
    h1.main-title {
      margin-top: 0.35em;
    }

  }
}
.page-header {
  padding-left: 1.125em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 0.5em;
  min-height: 2.375em;
  @include media-breakpoint-down(md) {
    padding-left: 0;
    display: block;

  }
}

.content-block {
  box-shadow: 0px 2px 3px rgba(0,0,0,0.1);
  background-color: $content-block-bg;
  @include color-mode(dark) {
    background-color: $content-block-bg-dark;
  }
  .content-block {
    box-shadow: none;
  }
  .content-block-header {
    margin-bottom: 1.5em;
    &.nobody {
      margin-bottom: 0;
    }
    & > h3 {
      @include block-title;
    }
  }
  &.bordered {
    padding: 1em;
    border: 1px solid $content-block-border-color;
    @include color-mode(dark) {
      border-color: $content-block-border-color;
    }
    border-radius: 4px;
  }
  &.small-head {
    h3 {
      font-size: inherit;
      margin-bottom: 0;
    }
    .content-block-header {
      margin-bottom: 0.5em;
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 50px;
  }

}

.dropzone {
  text-align: center;
  padding: 1em;
  border: 1px dashed $gray-500;
  border-radius: 4px;
  color: $gray-500;
  &:hover {
    background-color: $gray-300;
    @include color-mode(dark) {
      background-color: $gray-800;
    }
  }
}

.test-ribbon {
  width: 300px;
  top: 15px;
  left: -120px;
  text-align: center;
  padding: 10px;
  line-height: 15px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  z-index: 9999;
  position: fixed;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  background: rgba(255, 0, 0, 0.6);
  background-color: rgba(255, 0, 0, 0.6);
  pointer-events: none;
  text-transform: uppercase;
}
