$spacer: 1em;
$nav-link-padding-y: 0.5em;
$modal-footer-margin-between: .5em;
$font-size-base: 1em;
$form-floating-height: 1.6em;
$form-floating-padding-x: 0.7em;
$form-floating-padding-y: 0.5em;
$form-floating-input-padding-t: 1.1em;
$form-floating-input-padding-b: .3em;
$form-floating-line-height: 1.25;
$form-floating-label-transform: scale(.85) translateY(-.5em) translateX(.15em);
$headings-font-weight: bold;

$enable-dark-mode: true;
$color-mode-type: data; 

$white:    #fff;
$gray-100: #f0f1f2;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$mainColorExtraLight: #eddce3;
$mainColorLight: #db9cb4;
$mainColorPastel: #ad6d86;
$mainColorDarkmode: #ff4080;
$mainColor: #cf1452;
$mainColorDark: #a50f48;
$mainColorDarker: #840d3e;
$mainColorDarkest: #54102f;
$mainColorDarkestDeep: #31081a;
$mainColorBlack: #28121e;
$secondaryColorLight: #73e1f7;
$secondaryColor: #428f9e;
$secondaryColorDark: #26545e;

$blue:    #2373b5;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    $mainColor;
$red:     #d32941;
$orange:  #f46e0b;
$yellow:  #ffb600;
$lime:    #809914;
$green:   #299911;
$teal:    #0ea5a1;
$cyan:    #2caec4;
$light:   $gray-200;

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;
$theme:         $mainColor;

$bgBlue:    #a5cbf9;
$bgIndigo:  #c0c0ff;
$bgPurple:  #d6b0ff;
$bgPink:    #e8beed;
$bgCarmine: #ddafbd;
$bgRed:     #edaea2;
$bgOrange:  #eac49b;
$bgYellow:  #e0d88c;
$bgLime:    #bee874;
$bgGreen:   #a3ff9c;
$bgTeal:    #8fe2aa;
$bgCyan:    #8fe5e1;
$bgGrey:    #e6e6e6;
$bgBlueDark:    #244566;
$bgIndigoDark:  #27275b;
$bgPurpleDark:  #3d2360;
$bgPinkDark:    #581e60;
$bgCarmineDark: #4c192a;
$bgRedDark:     #4c1b18;
$bgOrangeDark:  #5e4327;
$bgYellowDark:  #605625;
$bgLimeDark:    #3b4f10;
$bgGreenDark:   #195416;
$bgTealDark:    #153f20;
$bgCyanDark:    #175651;
$bgGreyDark:    #666666;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "theme":      $theme
);

$body-bg: $gray-100;
$body-color: $gray-800;
$body-color-dark: $gray-200;
$input-border-color: $body-bg;
$content-block-bg: white;
$content-block-bg-dark: $gray-900;
$content-block-border-color: $gray-300;
$content-block-border-color-dark: $gray-700;
$modal-content-bg: white;

$enable-rounded: true;

$caret-width: 0.5em;
$caret-spacing: 0.75em;
$caret-vertical-align: middle;

$zindex-modal-backdrop2: 1062;
$zindex-modal2: 1064;
$zindex-image-viewer: 1070;

$grid-gutter-width: 20px !default;

$nav-tabs-border-radius: 0;
$nav-tabs-link-active-border-color: $mainColor;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-hover-border-color: $mainColor;
$nav-tabs-link-hover-bg: $gray-200;
$focus-ring-color-dark: rgba(#7bc3ff, .35);
$form-select-focus-box-shadow-dark: 0 0 0 .25rem $focus-ring-color-dark;

$grid-breakpoints: (
  xs: 0,
  tiny: 424px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
