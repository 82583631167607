html.kanban-page {
  scroll-behavior: unset;
}
.kanban-top-content {
  margin-bottom: 1em;
  .content-block-header {
    margin-bottom: 0!important;
  }
}
.kanban {
  .kanban-col {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 6em;
    .backlog-items {
      position: relative;
      padding: 5px 7px;
      background-color: white;
      flex-grow: 1;
      &.is-drag-over {
        background-color: lighten($blue, 40%);
      }
      @include color-mode(dark) {
        background-color: $gray-900;
        &.is-drag-over {
          background-color: darken($blue, 30%);
        }
      }
    }
    .kanban-col__title {
      background-color: white;
      color: $secondaryColor;
      display: flex;
      h2 {
        font-size: 1em;
        padding: 0.6em 0.8em;
        margin: 0;
        text-align: center;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-transform: uppercase;
        font-weight: normal;
        flex-grow: 1;
      }
      @include color-mode(dark) {
        color: $secondaryColorLight;
        background-color: $gray-900;
      }
      .dropdown-toggle {
        padding-left: 0.2em;
        padding-right: 0.2em;
        &::after {
          display: none;
        }
      }
    }
  }
  .card {
    margin-bottom: 7px;
  }
  .card {
    p {
      margin-bottom: 0.2em;
    }
  }
  .top-bot {
    padding: 2em 1em;
    text-align: center;
    opacity: 0;
    position: absolute;
    left: 8em;
    right: 0;
    background-color: white;
    border: 1px solid $input-border-color;
    border-radius: 4px;
    pointer-events: none;
    &:first-child {
      bottom: 100%;
      margin-bottom: 0.5em;
    }
    &:last-child {
      top: 100%;
    }
    &.is-drag-over {
      background-color: lighten($blue, 40%);
    }
    &.is-active {
      opacity: 0.9;
      box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    }
    & > div {
      display: none!important;
    }
    @include color-mode(dark) {
      background-color: black;
      &.is-drag-over {
        background-color: darken($blue, 30%);
      }
      &.is-active {
        box-shadow: 1px 1px 3px rgba(255,255,255,0.1);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .btn-group-status {
      background-color: white;
      padding: 10px;
      display: flex;
      @include color-mode(dark) {
        background-color: black;
      }
    }
    .kanban-col {
      display: none;
      &.active {
        display: block;
      }
      & > h2 {
        display: none;
      }
    }
    &.no-responsive {
      .kanban-col {
        display: block;
      }
    }
  }
}

.backlog-item-modal {
  .dropdown .badge {
    font-size: 1em;
  }
}

.backlog-add-col {
  position: relative;
  width: 35em;
  margin-right: 1.5em;
  @include media-breakpoint-down(xxl) {
    width: 28em;
  }
  @include media-breakpoint-down(xl) {
    width: 20em;
  }
  @include media-breakpoint-down(md) {
    width: auto;
    margin-bottom: 1em;
    .bt-resize {
      display: none;
    }
  }
  label {
    margin-top: 1em;
  }
  .ck-content {
    margin-top: 0;
  }
  .bt-resize {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
  &.closed {
    width: 3em;
    form {
      opacity: 0;
    }
    .dates {
      writing-mode: vertical-lr;
      margin-left: -0.25em;
      margin-top: 2em;
      white-space: nowrap;
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      .icon {
        transform: rotate(90deg);
      }
    }
    .graph {
      display: none;
    }
    .sprint-goal {
      display: none;
    }
  }
}

.kanban-search.filters {
  .filter-autocomplete {
    margin-right: 0.7em;
  }
  .autocomple-input-wrapper {
    display: block;
    input {
      width: 10em;
    }
  }
  .active-filters {
    display: block;
    margin-top: 0.5em;
  }
}
