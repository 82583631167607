.bulk-change-product-modal {
  .select-search {
    display: inline-block;
  }
  .form-group-tag {
    label {
      display: inline-block;
      margin-right: 1em;
      vertical-align: middle;
    }
    .form-control {
      display: inline-block;
      vertical-align: middle;
    }
  }
}