.form-group {
  margin: 0 0 1em;
  &.required label:after {
    content:" *";
    color: $mainColor;
  }
  &.inline {
    display: inline-block;
    margin: 0;
    label, .form-label {
      display: inline-block;
    }
  }
}

.form-control {
  width: auto;
  max-width: 100%;
  &.full-w {
    width: 100%;
  }
  @include color-mode(dark) {
    border-color: $gray-800;
    background-color: $gray-800;
    &:focus {
      box-shadow: $focus-ring-box-shadow-dark;
    }
  }
}
.text-center .form-control {
  margin-left: auto;
  margin-right: auto;
}

.form-select {
  @include color-mode(dark) {
    background-color: $gray-800;
    border-color: $gray-800;
    &:focus {
      border-color: $form-select-focus-border-color;
      outline: 0;
      @if $enable-shadows {
        @include box-shadow($form-select-box-shadow, $form-select-focus-box-shadow-dark);
      } @else {
        box-shadow: $form-select-focus-box-shadow-dark;
      }
    }

    &:disabled {
      color: $form-select-disabled-color;
      background-color: $form-select-disabled-bg;
      border-color: $form-select-disabled-border-color;
    }
  }
}

form {
  .actions {
    & > * {
      margin-right: 0.5em;
      margin-bottom: 0.5em;
    }
  }
  fieldset {
    margin: 1.5em 0;
    &.row {
      legend {
        color: $gray-600;
        text-align: right;
        font-size: 1em;
        padding-right: 2em;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 1em;
          bottom: 0;
          border-right: 1px solid $gray-300;
        }
      }
    }

  }
}

.form-value {
  color: $gray-600;
}
.rich-content-with-button {
  position: relative;
  min-height: 7em;
  padding-top: 2px;
  & > .btn {
    float: right;
    margin: 5px 10px 5px 5px;
  }
  .rich-content-placeholder {
    color: $gray-500;
  }
}
.rich-content-editable-button {
  position: relative;
  min-height: 7em;
  padding-top: 2px;
  & > .icon {
    float: right;
    margin: 5px 10px 5px 5px;
    font-size: 1em;
    width: 1em;
    height: 1em;
    color: $gray-400;
  }
  &:hover, &:active {
    & > .icon {
      color: $mainColor;
    }
    background-color: $body-bg;
    @include color-mode(dark) {
      background-color: $gray-800;
    }
  }
  .rich-content-placeholder {
    color: $gray-500;
  }
}

.field-update {
  position: relative;
  display: block;
  .field-value {
    display: block;
  }
  &.view {
    .icon {
      font-size: 1rem;
      width: 1em;
      height: 1em;
      margin-left: 1em;
      color: $gray-400;
    }
    &:hover {
      .icon {
        color: $mainColor;
      }
    }
  }
  &.edit {
    .field-value {
      visibility: hidden;
    }
  }
  &.fill-width {
    .form-group .input-wrapper {
      flex-grow: 1;
    }
  }
  .form-group {
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    .form-control {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
    }
    button {
      margin-left: 0.4em;
      padding: 0.375em;
      .icon {
        width: 1.4em;
        height: 1.4em;
      }
      &:hover {
        .icon {
          color: $mainColor;
        }
      }
    }
    .input-wrapper {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      .loader {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }
  &__textarea {
    display: block;
    &.edit {
      .field-value {
        display: none;
      }
    }
    &.view {
      position: relative;
      padding-right: 1.5em;

      .icon {
        position: absolute;
        top: 0.35em;
        right: 0;
      }
    }
    .form-group {
      position: static;
      transform: none;
    }
    .input-wrapper {
      flex-grow: 1;
    }
  }
}

.select-search {
  position: relative;

  .dropdown-menu {
    padding: 0;
    &.dropdown-menu-end {
      right: 0;
      left: auto;
    }
    &.w-260 {
      min-width: 320px;
    }
  }
  .btn-group-vertical {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .btn {
    text-align: left;
    border-color: $gray-200;
    @include color-mode(dark) {
      border-color: $gray-700;
    }
    mark {
      padding: 0;
    }
  }
  .form-control {
    width: 100%;
    margin-bottom: 0.3em;
  }
  .form-select {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
  }
  .input-wrapper {
    padding: 8px 6px 5px;
    position: relative;
    .loader {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  &.without-search {
    .dropdown-menu {
      padding: 0;
    }
  }
  &.fixed-width {
    &.w-1 {
      width: 10em;
    }
    &.w-2 {
      width: 15em;
    }
    &.w-3 {
      width: 20em;
    }
    &.w-4 {
      width: 25em;
    }
  }
}

.search-suggestions {
  .dropdown-menu {
    padding: 5px;
    min-width: 100%;
  }
  .btn-group-vertical {
    display: flex;
    margin-bottom: 0.7em;
    .btn {
      font-size: 0.875em;
      text-align: left;
    }
  }
  h3 {
    font-size: 1em;
  }
}

.autocomplete {
  .btn svg {
    margin-bottom: 0.2em;
    margin-left: 0.4em;
  }
  .autocomple-input-wrapper {
    position: relative;
    display: inline-block;
    input {
      padding-right: 1.5em;
    }
    .loader {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
    .options {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 10;
      &.closed {
        display: none;
      }
      .btn-group-vertical {
        background-color: white;
        display: flex;
        @include color-mode(dark) {
          background-color: $gray-900;
        }
      }
      .btn {
        text-align: left;
      }
    }
  }
  &-continuous {
    .autocomple-input-wrapper {
      .options {
        right: auto;
      }
    }
  }
}

.input-group-secret-token {
  max-width: 35em;
}

.code-group {
  .form-control {
    box-sizing: content-box;
    width: 1em;
    display: inline-block;
    margin-left: 0.5em;
    text-align: center;
    border: 1px solid $mainColor;
    background-color: white;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    &:valid,
    &:invalid,
    &.is-invalid {
      padding-right: 0.6rem;
      background-image: none;
    }
    &:first-child {
      margin-left: 0;
    }
    @include color-mode(dark) {
      border-color: $mainColorDarkmode;
      background-color: $black;
    }
  }
}

svg.qr-code {
  padding: 20px;
  background-color: white;
}

.search-suggestions {
  .loader {
    position: absolute;
    top: 50%;
    right: 2.5em;
    transform: translateY(-50%);
  }
}
