.backlog-item-full {
  .link-action {
    .dropdown-toggle {
      padding-left: 0.2em;
      padding-right: 0.2em;
      margin-right: 0.3em;
      &::after {
        display: none;
      }
      &:hover {
        color: $mainColor;
        @include color-mode(dark) {
          color: $mainColorDarkmode;
        }
      }
    }
  }
  .backlog-title {
    font-weight: normal;
    color: $mainColor;
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    @include color-mode(dark) {
      color: $mainColorDarkmode;
    }
    .title-element {
      display: inline-block;
      margin-left: 0.5em;
      font-weight: bold;
      color: $body-color;
      flex-grow: 1;
      @include color-mode(dark) {
        color: $body-color-dark;
      }
    }
    @include media-breakpoint-down(sm) {
      .field-update.edit {
        width: 100%;
        margin-left: 0;
      }
    }
  }
  .modal-dialog {
    max-width: $modal-xl;
  }
  .field-value {
    font-size: 0.875em;
    flex-wrap: wrap;
    .ms-auto {
      padding-left: 0.5em;
    }
    .icon {
      color: $gray-600;
    }
    .field-update.edit {
      width: 12em;
      input {
        width: 6em;
      }
      .btn {
        margin-left: 0;
      }
    }
    .select-search .form-select {
      max-width: 15em;
    }
  }
  .dropdown-toggle {
    padding: 0.35em 0.65em;
  }
  .field-group {
    margin-top: 0.5em;
    border-top: 1px solid $gray-300;
    .field-group-title {
      font-size: 0.875em;
      text-align: right;
      padding-bottom: 0.25em;
      color: $gray-500;
      text-transform: uppercase;
      text-decoration: none;
      padding-top: 0.25em;
      &:hover {
        color: $link-color;
        text-decoration: underline;
      }
      &::before {
        display: inline-block;
        vertical-align: middle;
        content: '';
        width: 0.5em;
        height: 0.5em;
        border-top: 2px solid $link-color;
        border-right: 2px solid $link-color;
        transform: rotate(135deg) translateX(-0.3em);
        margin-right: 0.5em;
        transition: transform 0.3s ease;
      }
    }
    &.is-close {
      .field-group-title {
        &::before {
          transform: rotate(45deg) translateY(-0.15em);
        }
      }
      .field-group-content {
        display: none;
      }
    }
  }
  .field-value + .field-value {
    margin-top: 0.5em;
  }
  .autocomplete-edit-area {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0.25em;
    &>:first-child {
      flex-shrink: 1;
      margin-right: 0.4em;
      .autocomple-input-wrapper {
        max-width: 100%;
      }
      input {
        box-sizing: border-box;
        width: 100%;
      }
    }
  }
  .bt-edit-versions,
  .bt-edit-tags {
    padding: 0.4em 0;
    display: block;
    text-align: right;
  }
  .bt-close-versions,
  .bt-close-tags {
    white-space: nowrap;
    .icon {
      color: inherit;
    }
  }

  .field-versions,
  .field-tags {
    .bt-edit-versions,
    .bt-edit-tags {
      & > .icon {
        width: 1em;
        height: 1em;
      }
      &:hover > .icon {
        color: $mainColor;
        @include color-mode(dark) {
          color: $mainColorDarkmode;
        }
      }
    }
    .autocomplete {
      position: relative;
      .bt-close-tags,
      .bt-close-versions {
        position: absolute;
        top: 0px;
        right: 0px;
        border: 0;
        svg {
          margin-left: 0;
        }
      }
    }
  }
  .field-deadline {
    .field-update {
      .form-group {
        left: -4em;
        input {
          width: 10em;
        }
      }
    }
  }
  .attachment-list {
    display: flex;
    flex-flow: row wrap;
    .attachment-list-item {
      position: relative;
      height: 5em;
      width: 8em;
      padding: 0.4em;
      border: 1px solid $gray-200;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      background-size: cover;
      background-position: 50% 50%;
      &:hover {
        a, .btn-simplelink {
          background-color: rgba($mainColorDark, 0.5);
          color: white;
          .title {
            color: white;
            background-color: $mainColorDark;
          }
        }
      }
      @include color-mode(dark) {
        border-color: $gray-700;
      }
      a, .btn-simplelink {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        text-align: center;
        color: $body-color;
        width: 100%;
        background: transparent;
        svg {
          width: 2em;
          height: 2em;
          margin: 0 auto 1em;
        }
      }
      .title {
        position: absolute;
        padding: 0.2em 0.4em;
        background-color: $gray-200;
        color: $body-color;
        text-decoration: none;
        font-size: 0.7em;
        left: 0;
        right: 0;
        bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: center;
        @include color-mode(dark) {
          background-color: $gray-800;
          color: white;
        }
      }
      .actions {
        position: absolute;
        top: 0.2em;
        right: 0.4em;
        .btn {
          border: 0;
          padding: 0;
          margin-left: 0.25em;
          color: white;
        }
      }
    }
  }
  .comments {
    .comments__list {
      margin-top: 2em;
    }
    .comment {
      padding: 1em 0;
      border-top: 1px solid $gray-300;
      position: relative;
      &.highlighted {
        background-color: $blue-100;
        .comment-body {
          margin-right: 1em;
          background: white;
          padding: 1em;
        }
        @include color-mode(dark) {
          background-color: $mainColorDarkest;
          .comment-body {
            background: $gray-900;
          }
        }
      }
      .contextual-menu-dropdown {
        position: absolute;
        top: 1em;
        right: 1em;
      }
      .comment-header {
        margin-bottom: 1em;
        color: $gray-500;
        h5 {
          display: inline-block;
          font-size: 1em;
          margin: 0 1em 0 0;
        }
      }
      .comment-footer {
        font-size: 0.875em;
        color: $gray-500;
        h5 {
          display: inline-block;
          font-size: 0.875em;
          margin: 0 0 0 1em;
        }
      }
      .comment-avatar {
        padding-top: 0.25em;
        float: left;
      }
      .comment-body {
        margin-left: 80px;
      }
    }
  }
  .backlog-comments-add {
    margin: 1em 0;
    form {
      max-width: 50em;
    }
    .label-container {
      text-align: right;
      margin: 0.5em 0 0;
    }
    .label {
      display: inline-block;
      padding: 0.4em 0.8em;
      margin: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background-color: $input-border-color;
      @include color-mode(dark) {
        background-color: $gray-800;
      }
    }
    .ck-editor__editable_inline {
      margin: 0 0 0.5em;
    }
  }
  .worklogs {
    td .dateblock {
      white-space: nowrap;
    }
    .comment {
      width: 100%;
    }
  }
  @include media-breakpoint-down(sm) {
    .dateblock {
      font-size: $font-size-sm;
    }
    .worklogs {
      tr {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        border-bottom: 1px solid $table-border-color;
      }
      td:first-child {
        width: 100%;
      }
      td {
        display: block;
        border: 0;
        box-sizing: border-box;
        width: 50%;
        padding-top: 0;
        padding-bottom: 0;
      }
      .contextual-menu-dropdown {
        position: absolute;
        top: 0;
        right: 0;
      }
      .comment {
        padding-bottom: 10px;
      }
      #work {
        width: 8em;
      }
      form .d-flex {
        display: block!important;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .item-link:not(:hover), .attachment-list-item:not(:hover) {
      .actions {
        button {
          @include visually-hidden-focusable;
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    .item-link, .attachment-list-item {
      .actions {
        flex-grow: 1;
        text-align: right;
      }
    }
  }
  .item-link {
    display: flex;
    align-items: center;
    .actions {
      .btn {
        border: 0;
        padding: 5px;
        margin-left: 10px;
        &:hover {
          color: $mainColor;
          @include color-mode(dark) {
            color: $mainColorDarkmode;
          }
        }
      }
    }
    &.hidden-label {
      .item-link-label {
        visibility: hidden;
      }
    }
    &.first {
      border-top: 1px solid $gray-300;
    }
    .item-link-label {
      width: 8em;
      padding: 0.4em 0;
    }
    .item-link-item {
      padding: 0.4em 0;
    }
    @include media-breakpoint-down(sm) {
      flex-flow: row wrap;
      .item-link-label {
        color: $gray-600;
        width: 100%;
      }
      &.hidden-label {
        .item-link-label {
          display: none;
        }
      }
      .item-link-item {
        width: 86%;
      }
      &.first {
        border-top: 1px solid $gray-300;
        margin-top: 5px;
      }
    }
  }
  .item-link-edit {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.backlog-item-teaser {
  background-color: $gray-100;
  text-decoration: none;
  @include color-mode(dark) {
    background-color: $gray-800;
  }
  .title {
    position: relative;
    padding-bottom: 1.4em;
    .h-title {
      position: absolute;
      display: block;
      bottom: 0.5em;
      left: 0;
      right: 2.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0.1em 0.1em 0.1em 0;
      &:hover {
        right: auto;
        z-index: 5;
        background-color: $gray-100;
        @include color-mode(dark) {
          background-color: $gray-800;
        }
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .org-prod {
    color: $body-color;
    text-decoration: none;
    font-size: 0.75em;
    margin-top: 0.4em;
    color: $gray-600;
    text-transform: uppercase;
  }
  .ref {
    display: inline-block;
    font-weight: normal;
    color: $mainColor;
    margin-right: 2.4em;
    font-size: 0.85em;
    vertical-align: middle;
    margin-bottom: 0.1em;
    @include color-mode(dark) {
      color: $mainColorDarkmode;
    }
    .parent-ref {
      color: $body-color;
      margin-left: 0.5em;
      @include color-mode(dark) {
        color: $body-color-dark;
      }
    }
  }
  .card-body {
    padding: 0.3em 0.5em 0.4em 0.9em;
  }
  .values {
    font-size: 0.85em;
    color: $gray-600;
    .val {
      display: inline-block;
    }
    .val + .val {
      margin-left: 0.5em;
    }
  }
  .badge {
    font-weight: normal;
  }
  .badge.assignee {
    background-color: white;
    @include color-mode(dark) {
      background-color: black;
    }
  }
  .badge.organization-slug,
  .badge.product-slug {
    text-transform: uppercase;
  }
  .btn-sm {
    padding: 0.15em 0.4em 0.25em;
  }
  &.backlog-item-small {
    margin-bottom: 4px;
    .card-body {
      padding: 0.3em 0.6em 0.3em 0.9em;
    }
  }
  .h6 {
    font-weight: normal;
  }
  .item-type {
    text-transform: lowercase;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    width: 7px;
    overflow: hidden;
    span {
      display: block;
      position: absolute;
      font-size: 0.875em;
      top: 4px;
      left: 0%;
      background-color: white;
      border: 1px solid $gray-300;
      padding: 0.25em 0.5em;
      z-index: 2;
      opacity: 0;
      transition: left 0.2s ease;
      border-radius: 4px;
      margin-left: 5px;
      @include color-mode(dark) {
        background-color: black;
        border: 1px solid $gray-700;
      }
    }
    &:hover {
      overflow: visible;
      span {
        opacity: 1;
        left: 100%;
      }
    }
  }
  &.backlog-item-subtask {
    .backlog-item--infos {
      padding-right: 28px;
      min-height: 1.8em;
      flex-flow: row wrap;
      position: relative;
      .bt-more {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .title {
      padding-bottom: 0;
      .h6 {
        margin-bottom: 0;
      }
      .badge {
        font-size: 0.8em;
      }
      .ref {
        margin-right: 0.5em;
      }
    }
    .h-title {
      display: block;
      margin-top: -0.2em;
      margin-right: 2.5em;
    }
    .badge {
      vertical-align: top;
    }
    &.is-current {
      background-color: $gray-200;
      @include color-mode(dark) {
        background-color: $gray-700;
      }
    }
    &.is-parent {
      margin-bottom: 0.4em !important;
    }
    &.is-parent ~ &.is-child {
      margin-left: 1.2em;
      &::before {
        content: '';
        position: absolute;
        left: -0.7em;
        top: 0;
        height: 110%;
        width: 1em;
        border-left: 1px solid $gray-600;
        border-bottom: 1px solid $gray-600;
        transform: translateY(-50%);
      }
    }
    &.is-parent + &.is-child {
      &::before {
        top: 15%;
        height: 70%;
        transform: translateY(-35%);
      }
    }
  }
  .badge svg {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.4em;
  }
}

.rich-content {
  word-break: break-word;
  button {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  figure.image-style-side {
    float: right;
    margin-left: 1em;
    max-width: 50%;
  }
  figure.image-inline {
    display: inline-block;
  }
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  code {
    display: block;
    padding: 1em;
    margin: 0.5em 0 1.5em 0;
    border-radius: 4px;
    background-color: $gray-100;
    border: 1px solid $gray-300;
    white-space: pre-wrap;
    @include color-mode(dark) {
      background-color: $gray-800;
      color: white;
      border-color: $gray-600;
    }
  }
  span.mention {
    color: $mainColor;
    @include color-mode(dark) {
      color: $mainColorDarkmode;
    }
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 200px;
  text-transform: uppercase;
  &.anonymous {
    background-color: $gray-200;
    &::after {
      content: 'X';
      color: $gray-500;
    }
  }
}
.avatar-img {
  border-radius: 200px;
}

.backlogitem-actions-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
}
