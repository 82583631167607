.backlog-items-import-binding-page {
  form > .select-group {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-500;
    margin-bottom: 0.5em;
    padding-bottom: 0.4em;
    .form-group {
      margin-bottom: 0;
    }
    & > * {
      width: 24%;
    }
    select {
      width: auto;
      max-width: 100%;
    }
    .example {
      align-self: center;
      padding: 0.5em 0.8em;
      border: 1px solid $gray-500;
      
      overflow: hidden;
      display: -webkit-box;
      min-height: 2.6em;
      max-height: 6em;
    }
    @include media-breakpoint-down(md) {
      flex-flow: row wrap;
      & > * {
        width: 48%;
      }
      .form-group {
        margin-bottom: 0.4em;
      }
    }
    @include media-breakpoint-down(sm) {
      display: block;
      & > * {
        width: auto;
        margin-bottom: 0.4em;
      }
    }
  }
}