.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modal-backdrop2;
  background: rgba(0,0,0,0.85);
  padding: 1em 2em;
  cursor: default;
  .header {
    color: $gray-500;
  }
  .btn-close {
    vertical-align: middle;
  }
  .actions {
    text-align: right;
  }
  a.link {
    color: $gray-500;
    vertical-align: middle;
    margin-right: 1em;
    &:hover {
      color: $gray-300;
    }
  }
  &__image {
    position: absolute;
    top: 3em;
    right: 1em;
    bottom: 1em;
    left: 1em;
    display: flex;
    .loader {
      margin: auto;
    }
    img {
      margin: auto;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
    .bt-left, .bt-right {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: white;
      font-size: 1.5em;
    }
    .bt-left {
      left: 0;
    }
    .bt-right {
      right: 0;
    }
  }
}
