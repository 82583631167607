.home-page {
  background: linear-gradient(172deg, #840d3e 0%, #428f9e 100%);
  @include color-mode(dark) {
    background: linear-gradient(172deg, #470621 0%, $secondaryColorDark 100%);
  }
  min-height: 100vh;
  overflow: hidden;
  padding: 0 15px;
  & > .bg {
    position: fixed;
    z-index: 1;
    left: 17%;
    right: 15%;
    top: 50%;
    &::after {
      content: '';
      display: block;
      padding-top: 77.22%;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
  }
  main {
    position: relative;
    z-index: 2;
    padding-top: 9em;
  }
  .content-container {
    position: relative;
    a {
      color: $mainColorLight;
    }
  }
  .icon-container {
    width: 262px;
    height: 50px;
    margin: 0 auto;
    top: -110px;
    border-radius: 400px;
    padding-top: 15px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .invalid-feedback {
    color: $mainColorLight;
  }
  .loader-container {
    background-color: rgba(0,0,0,0.2);
  }
  .btn-simplelink {
    color: $gray-400;
  }
}
.page-login {
  .content-container {
    border-radius: 20px;
  }
}
.login-block {
  text-align: center;
  padding-bottom: 1em;
  h1 {
    margin-top: 0;
    padding: 0 0.7em;
  }
  svg {
    display: block;
    max-width: 100%;
  }
  form {
    padding: 0 1.4em;
    label {
      display: block;
    }
    .form-check-input {
      float: none;
      margin-right: 0.75em;
    }
    .form-check-label {
      display: inline-block;
    }
    &.form-login .form-control {
      margin: 0 auto;
    }
  }
  .code-group {
    .btn-clear {
      color: white;
      &:hover {
        color: $mainColorLight;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      padding: 0;
    }
    form {
      padding: 0;
    }
  }
}

.home-page {
  .content-container {
    max-width: 430px;
    margin: 0 auto 2em;
    padding: 1.5em;
    background: rgba(255,255,255,0.1);
    border: 1px solid white;
    color: white;
    h1.main-title {
      color: white;
      margin: 0 0 0.5em;
      border-radius: 5px;
      display: block;
      text-align: center;
    }
    .form-login {
      .form-control {
        width: 100%;
      }
    }
    
  }
  &.home-page-static {
    .content-container {
      background: transparent;
      border: 1px solid transparent;
      .content-content {
        margin-left: -1.5em;
        margin-top: -1.5em;
        margin-right: -1.5em;
        background: rgba(255,255,255,0.1);
        border: 1px solid white;
      }
    }
  }
  &.signup-form-page {
    .content-container {
      max-width: 800px;
      padding: 0;
    }
    .hero {
      background-color: rgba(0,0,0,0.1);
      text-align: right;
      h1 {
        color: $mainColorLight;
      }
      p {
        font-size: 1.5em;
      }
    }
    .cols {
      display: flex;
      & > * {
        box-sizing: border-box;
        width: 50%;
        padding: 2em;
      }
    }
    @include media-breakpoint-down(sm) {
      .cols {
        display: block;
        & > * {
          width: auto;
        }
      }
    }
  }
}
.page-loading {
  .content-container {
    border: 0;
    background-color: transparent;
  }
}
.page-error {
  .content-container {
    max-width: 800px;
  }
}

.body-home {
  .topmenu {
    background: transparent;
  }
  .dropdown {
    .dropdown-toggle {
      color: white;
      &:after {
        border-color: white;
      }
    }
  }
}