.history-item {
  padding: 1em 0;
  border-bottom: 1px solid $gray-400;
  &:last-child {
    border-bottom: 0;
  }
  &__header {
    margin-bottom: 0.5em;
    .dateblock {
      color: $gray-600;
    }
  }
  .comment {
    padding: 0.5em;
    border-radius: 3px;
    margin-top: 0.25em;
    background-color: $body-bg;
    @include color-mode(dark) {
      background: $gray-900;
    }
  }
  .history-item-value, .history-item-summary {
    margin-top: .125em;
    .icon {
      font-size: 1rem;
      width: 1em;
      height: 1em;
    }
  }
}
