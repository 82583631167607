nav.sidebar {
  background: $mainColorDarkest;
  background: linear-gradient(172deg, #840d3e 0%, $secondaryColor 100%);
  box-sizing: border-box;
  position: relative;
  transition: width 0.3s ease;
  @include color-mode(dark) {
    background: linear-gradient(172deg, #470621 0%, $secondaryColorDark 100%);
  }

  .sidebar-expand {
    position: absolute;
    top: 1em;
    right: 0.5em;
    padding: 0.5em;
    color: white;
    transition: transform 0.3s ease;
    
  }
  .sidebar-head {
    background-color: $mainColorDarkest;
    text-align: center;
    padding: 1.5em;
    @include color-mode(dark) {
      background-color: $mainColorDarkestDeep;
    }
    img {
      max-width: 150px;
    }
    .organization-name {
      color: white;
      margin: 0.5em 0 0;
    }
    .form-floating {
      margin-top: 1.4em;
      label {
        top: 0.3em;
        color: $mainColorLight;
        padding: 0.3em 0.7em;
        transform: translateX(2.4em);
      }
      input {
        background: $mainColorDarkest;
        color: white;
        border-color: $mainColorPastel;
        padding: 0.3em 2.4em 0.3em 2.4em;
        height: 2.2em;
      }
      input:not(:placeholder-shown) {
        padding: 0.3em 2.4em 0.3em 2.4em;
      }
      input:focus ~ label,
      input:not(:placeholder-shown) ~ label {
        top: 0;
        padding: 0;
        background: transparent;
        transform: scale(0.85) translateY(-1.6em);
        &::after {
          display: none;
        }
      }
      
    }
  }

  .nav-link {
    color: white;
    padding: 0.5em 1.5em;
    &:hover {
      color: $mainColorLight;
    }
    &.active {
      background-color: $mainColorBlack;
    }
  }

  .product-context {
    background-color: $mainColor;
    padding: 0.4em 1.5em 0.7em;
    display: block;
    text-decoration: none;
    transition: background 0.2s esae;
    &:hover {
      background-color: $mainColorDark;
    }
    .small {
      font-size: 0.7em;
      color: $mainColorLight;
    }
    h2 {
      font-size: 1em;
      margin: 0;
      color: white;
      font-weight: normal;
    }
    @include color-mode(dark) {
      background-color: $mainColorDarker;
      &:hover {
        background-color: $mainColorDarkest;
      }
    }
  }

  & > .main-menu {
    margin-top: 0.5em;
    .mobile-links {
      margin-top: 0.5em;
      display: block;
      width: 100%;
      border-top: 1px solid rgba(255,255,255,0.3);
      .dropdown {
        display: inline-block;
      }
    }
    .bt-theme {
      color: white;
      &:hover {
        color: $mainColorLight;
      }
    }
  }

  .menu-button {
    display: none;
  }

  .sidebar-search {
    position: relative;
    .btn {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: white;
      opacity: 0.5;
    }
    .key-info {
      position: absolute;
      top: 50%;
      right: 0.7em;
      transform: translateY(-50%);
    }
    .btn:focus,
    input:focus ~ .btn {
      opacity: 1;
    }
  }

  .show-reduced {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    width: 300px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;

    &.reduced {
      width: 44px;
      overflow: visible;
      z-index: 10;
      .show-reduced {
        display: block;
      }
      .hide-reduced {
        display: none;
      }
      .sidebar-head {
        padding: 0;
        .app-icon.show-reduced {
          display: block;
          padding: 7px;
        }
        .btn {
          color: white;
        }
      }
      .sidebar-expand {
        background-color: $mainColorDarkest;
        position: static;
        width: 100%;
        transform: rotate(180deg);
      }
      .organization-name,
      .product-context {
        display: none;
      }
      .mini-link,
      .nav-link {
        display: inline-block;
        padding: 0.5em;
        position: relative;
        & > span {
          position: absolute;
          display: none;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          white-space: nowrap;
          padding: 5px 10px;
          background-color: $mainColorDarkest;
          margin-left: 5px;
          border-radius: 5px;
        }
        &:hover {
          color: $mainColorLight;
          & > span {
            display: block;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 0;
    display: block;
    z-index: $zindex-fixed;
    background: linear-gradient(to left, $mainColorDarkest 0%, $mainColorBlack 100%);
    .sidebar-expand {
      display: none;
    }
    .sidebar-head {
      background: transparent;
      padding: 10px 20px;
      img {
        max-width: none;
        width: 120px;
      }
    }
    .menu-button {
      display: block;
      border: 0;
      background: transparent;
      color: white;
      position: absolute;
      top: 8px;
      right: 10px;
    }
    .organization-name,
    .product-context,
    .sidebar-search {
      display: none;
    }
    .app-icon.hide-reduced {
      width: 39px;
      height: 30px;
      display: block;
    }
    & > .main-menu {
      position: fixed;
      display: block;
      top: 50px;
      right: -400px;
      bottom: 0;
      padding-top: 10px;
      margin: 0;
      background: $mainColorDarkest;
      background: linear-gradient(to bottom, $mainColorDarkest 0%, $mainColorBlack 100%);
      transition: right 0.3s ease;
    }
    &.menu-opened > .main-menu {
      right: 0;
    }
  }
}
@include color-mode(dark) {
  .tooltip.tooltip-search .key-info {
    color: black;
  }
}
@include media-breakpoint-down(tiny) {
  nav.sidebar .app-icon.show-reduced {
    width: 32px;
    margin-bottom: 4px;
    display: block;
    span {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
  nav.sidebar .app-icon.hide-reduced {
    display: none;
  }
}

.sidebar.sidebar-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: white;
  box-shadow: 0px 5px 5px rgba(0,0,0,0.2);
  width: 1160px;
  max-width: 100%;
  border-right: 1px solid $gray-400;
  z-index: $zindex-modal;
  overflow: auto;
  @include color-mode(dark) {
    background: black;
  }
  .btn-close {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .head {
    background: $body-bg;
    padding: 1.5em;
    @include color-mode(dark) {
      background: $gray-900;
    }
  }
  
  .sidebar-search {
    input {
      background-color: white;
      @include color-mode(dark) {
        background-color: black;
      }
      box-sizing: border-box;
      width: 100%;
    }
    .btn {
      color: $gray-600;
      opacity: 0.5;
      @include color-mode(dark) {
        color: $gray-400;
      }
    }
    .btn:focus,
    input:focus ~ .btn {
      opacity: 1;
    }
  }
  .sidebar-content {
    padding: 1em;
    position: relative;
    min-height: 4em;
  }
  .list-group-item {
    .card-body {
      padding: 0;
      text-align: left;
    }
    &:hover, &:focus {
      background-color: $gray-200;
      @include color-mode(dark) {
        background-color: $gray-800;
      }
    }
  }
  .list-items {
    columns: 3;
    @include media-breakpoint-down(md) {
      columns: 2;
    }
    @include media-breakpoint-down(sm) {
      columns: 1;
    }
    .card {
      break-inside: avoid;
      overflow: hidden;
    }
  }
  .row .list-items {
    columns: 2;
    @include media-breakpoint-down(sm) {
      columns: 1;
    }
  }
  .organization__teaser,
  .product__teaser {
    .extra-links {
      a {
        display: block;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .product__teaser {
    .extra-links {
      margin-top: 0.5em;
    }
  }
}

#sidebar-search.form-control {
  padding-left: 3em;
}